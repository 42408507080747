var DESCRIPTORS = require(22);
var definePropertyModule = require(55);
var createPropertyDescriptor = require(20);

module.exports = DESCRIPTORS ? function (object, key, value) {
  return definePropertyModule.f(object, key, createPropertyDescriptor(1, value));
} : function (object, key, value) {
  object[key] = value;
  return object;
};
